@import url(//fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css);
*, *:after, *:before { box-sizing: border-box; }
body, html, #root { font-size: 100%; padding: 0; margin: 0;}
html {
    scroll-behavior: smooth;
}

.clearfix:before, .clearfix:after { content: " "; display: table; }
.clearfix:after { clear: both; }

body {
    font-family: 'Lato', Calibri, Arial, sans-serif;
    color: #333;
    background: #fff;
    position: relative;
    line-height: 1.5;
}

.container {
    min-height: 100vh;
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.santa-wrapper{
    width:120px;
    height:120px;
    position: absolute;
    top: 30px;
    right: 15px;
}

.santa-wrapper * {
    box-sizing: border-box;
    margin:0;
    padding:0;
  }
  
  
  .santa {
      background:#3ac6f4;
      width:120px;
      height:120px;
      border-radius:50%;
      position:relative;
  }
  
  .santa .snow {
      width: 2px;
      height: 2px;
      background: white;
      border-radius: 50%;
      position: absolute;
      left: 18px;
      top: 44px;
  }
  
  
  .santa .snow:after {
      width: 6px;
      height: 6px;
      content: "";
      display: inline-block;
      background: white;
      border-radius: 50%;
      position: absolute;
      top: -14px;
      left: 0px;
  }
  
  .santa .snow:before {
      width: 4px;
      height: 4px;
      content: "";
      display: inline-block;
      background: white;
      border-radius: 50%;
      position: absolute;
      bottom: -12px;
      left: -11px;
  }
  
  .santa .hat {
      width: 64px;
      height: 31px;
      border-radius: 200px 200px 0 0;
      background: #ef4753;
      margin: 0 auto;
      top: -11px;
      position: relative;
      left: 5px;
      z-index:20;
  }
  
  .santa .hat:after {
      width: 60px;
      height: 15px;
      border-radius: 20px;
      content: "";
      display: block;
      background: white;
      position: relative;
      top: 22px;
      left: -3px;
  }
  
  .santa .face {
      width: 54px;
      height: 57px;
      background: white;
      border-radius: 0px 0px 50px 50px;
      margin: 0 auto;
      position: relative;
      z-index: 20;
      top: -6px;
  }
  
  .santa .face .eyes {
      background:#f1ddd5;
      width:45px;
      height:15px;
      margin:0 auto;
      position:relative;
      left:-1px;
      text-align: center;
      top:4px;
      padding-top: 0px;
  }
  
  .santa .face .eyes:after, righteye {
      width: 8px;
      height: 6px;
      background: transparent;
      box-shadow: 0px -2px #793623;
      content: "";
      display:inline-block;
      margin:0 auto;
      border-radius: 50%;
  }
  
  .santa .face .eyes:before, lefteye {
      width: 8px;
      height: 6px;
      background: transparent;
      box-shadow: 0px -2px #793623;
      content: "";
      display:inline-block;
      margin:0 auto;
      border-radius: 50%;
      margin-right:5px;
  }
  
  .santa .mouth {
      background:#a31f2b;
      border-radius:50%;
      width:6px;
      height:6px;
      position:absolute;
      bottom:20px;
      right:17px;
  }
  
  .santa .body {
      width: 93px;
      height: 84px;
      background: #ef4753;
      border-radius: 90px 90px 0px 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align:center;
      z-index:10;
  }
  
  .santa .body:after {
      width: 5px;
      height: 5px;
      background: white;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 68px;
      z-index: 50;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
  }
  
  .santa .body:before {
      width: 5px;
      height: 5px;
      background: white;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 55px;
      z-index: 50;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
  }
  
  .santa .hat .hat-end {
      width: 13px;
      height: 32px;
      content: "";
      background: #ef4753;
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 15px;
      border-radius: 0px 11px 0px 0px;
  }
  
  .santa .hat .hat-end:after {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: white;
      content: "";
      display: inline-block;
      top: 30px;
      position: absolute;
      left: 0px;
      z-index: 11;
  }
  
  .circles {
      background: #a31f2b;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      z-index: 9;
      position: absolute;
      right: -6px;
      top: 12px;
  }
  
  .circles:after {
      background:white;
      width:40px;
      height:40px;
      right:0;
      top:50px;
      content:"";
      border-radius:50%;
  }
  
  .dirty-overflow {
      overflow: hidden;
      width: 120px;
      height: 76px;
      border-radius: 0px 0px 105px 105px;
      position: absolute;
      left: 0;
      bottom: 0;
  }
@font-face {
	font-family: 'nlicons';
	src:url('/fonts/nlicons.eot');
	src:url('/fonts/nlicons.eot?#iefix') format('embedded-opentype'),
		url('/fonts/nlicons.woff') format('font-woff'),
		url('/fonts/nlicons.ttf') format('truetype'),
		url('/fonts/nlicons.svg#nlicons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.first-slide {
    color: white;
    background: #046A38; /* ##c54245;*/
    font-weight: 300;
}
#terms{
    max-width: 1000px;
    margin: 0 auto;
    font-size: small;
}
.main {
    margin: 0 auto;
    padding: .5em;
    max-width: 60em;
    font-size: 1.6em;
    position: relative;
    z-index: 50;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
@media screen and (min-width: 30em) {
    .main {
        font-size: 2em;
    }
}
    table {
        font-size: 60%;
        margin-top: 1em;
        width: 100%;
    }

        table th, table td {
            text-align: left;
            padding: 8px;
        }

        table tbody tr:nth-child(odd) {
            background: #f5f5f5;
        }
        table tbody tr.secret {
            background: #e7fff3;
        }

        table td p {
            margin: 0;
        }

            table td p + p {
                margin-top: .5em;
            }

    /* general style for the form */
    .nl-form {
        text-align: center;
        
        text-shadow: 1px 1px 4px black;
    }

        .nl-form ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-shadow: none;
        }

        /* normalize the input elements, make them look like everything else */
        .nl-form input,
        .nl-form select,
        .nl-form button,
        .nl-button {
            text-shadow: none;
            border: none;
            background: transparent;
            font-family: inherit;
            font-size: inherit;
            color: inherit;
            font-weight: inherit;
            line-height: inherit;
            display: inline-block;
            padding: 0;
            margin: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

            .nl-form input:focus {
                outline: none;
            }

        .nl-form input {
            max-width: 3em;
            text-align: right;
        }

        .nl-form select {
            max-width: 6em;
        }

    /* custom field (drop-down, text element) styling  */
    .nl-field {
        display: inline-block;
        position: relative;
        margin: 0 .5rem;
    }

        .nl-field.nl-field-open {
            z-index: 10000;
        }

    /* the toggle is the visible part in the form */
    .nl-field-toggle,
    .nl-form input,
    .nl-form select {
        line-height: inherit;
        display: inline-block;
        color: white;
        cursor: pointer;
        border-bottom: 1px dashed white;
    }

    /* drop-down list / text element */
.nl-field ul {
    position: fixed;
    visibility: hidden;
    background: #76C3BD;
    background: rgba(118, 195, 189, .9);
    left: 0;
    font-size: 80%;
    opacity: 0;
    -webkit-transform: translateY(-30%) scale(0.9);
            transform: translateY(-30%) scale(0.9);
    transition: visibility 0s 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

    .nl-field.nl-field-open ul {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(-40%) scale(1);
                transform: translateY(-40%) scale(1);
        transition: visibility 0s 0s, opacity 0.3s, -webkit-transform 0.3s;
        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

    .nl-field ul li {
        color: #fff;
        position: relative;
    }

    .nl-dd ul li {
        padding: 0 1em;
        cursor: pointer;
        white-space: nowrap;
    }


.nl-dd ul li.nl-dd-checked {
    color: #478982;
}

     .nl-dd ul li:hover {
        background: rgba(0,0,0,0.05);
    }

         .nl-dd ul li:hover:active {
            color: #478982;
        }

    /* icons for some elements */
    .nl-dd ul li.nl-dd-checked:before,
    .nl-submit:before,
    .nl-button:before,
    .nl-field-go:before {
        font-family: 'nlicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }

    .nl-dd ul li.nl-dd-checked:before {
        content: "\E000";
        position: absolute;
        right: 1em;
        font-size: 50%;
        line-height: 3;
    }

    .nl-ti-text ul {
        min-width: 5em;
    }
        .nl-ti-text ul li.nl-ti-input {
            text-align:left;
        }

        .nl-ti-text ul li.nl-ti-input input {
            width: 100%;
            padding: 0.2em 0 0.2em 0.5em;
            border-bottom: none;
            color: #fff;
            max-width: 3em;
            text-align: left;
        }

    .nl-form .nl-field-go {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        cursor: pointer;
        background: rgba(0,0,0,0.1);
        width: 1.8em;
        text-align: center;
        color: transparent;
    }

    .nl-field-go:before {
        content: "\E001";
        font-size: 75%;
        color: #fff;
        width: 100%;
        line-height: 2.5;
        display: block;
    }

    /* custom placeholder color */
    .nl-form input::-webkit-input-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-webkit-input-placeholder,
    .nl-form input:focus::-webkit-input-placeholder {
        color: rgba(255,255,255,0.2);
    }

    .nl-form input::-moz-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-moz-placeholder,
    .nl-form input:focus::-moz-placeholder {
        color: rgba(255,255,255,0.2);
    }

    .nl-form input:-ms-input-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-ms-input-placeholder,
    .nl-form input:focus::-ms-input-placeholder {
        color: rgba(255,255,255,0.2);
    }

    /* example field below text input */
    .nl-ti-text ul li.nl-ti-example {
        font-size: 40%;
        font-style: italic;
        font-weight: 400;
        padding: 0.4em 1em;
        color: rgba(0,0,0,0.2);
        border-top: 1px dashed rgba(255,255,255,0.7);
    }

        .nl-ti-text ul li.nl-ti-example em {
            color: #fff
        }


    .nl-form .nl-submit,
    .nl-button {
        line-height: 3;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        background: #bf9b30; /*#76C3BD; */
        color: #fff;
        padding: 0 1em 0 0;
        font-size: 50%;
        font-weight: bold;
        letter-spacing: 3px;
        margin-top: 1em;
    }
    .nl-button a{ color: #fff; text-decoration: none }

        .nl-submit:before,
        .nl-button:before {
            content: "\E001";
            color: #fff;
            float: left;
            padding: 0 0.7em;
            margin: 0 0.8em 0 0;
            background:#a67c00; /* #69B1A9;*/
        }

        .nl-button:before {
            content: "\E001";
        }
         .nl-submit + .nl-submit {margin-left:1em}

    /* overlay becomes visible when a field is opened */
    .nl-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        opacity: 0;
        z-index: 9999;
        visibility: hidden;
        transition: visibility 0s 0.3s, opacity 0.3s;
    }
    .nl-form small.divider{
        display: block;
        margin-top: .4em;
    }

    .nl-field.nl-field-open ~ .nl-overlay {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
    .login-wrapper .login {
        background:rgba(58,63,68,0.5);
        border-radius: 5px;
        box-shadow: 0 1.5px 0 0 rgba(0,0,0,0.1);
        width:450px;
        display: -webkit-flex;
        display: flex;  
        -webkit-flex-direction: column;  
                flex-direction: column;
    }
    .form{
        padding:25px 25px 0;
        margin: 20px 20px 0;	
        border-radius: 3px;
    }
    .form input {
        border: 0;
        color: inherit;
        font: inherit;
        margin: 0;
        outline: 0;
        padding: 0;
        transition: background-color .3s;
      }
      
      .form-login .user:before {
        content: '\F007';
        font: 14px fontawesome;
          color: #5b5b5b;
      }
      
      .form-login .lock:before {
        content: '\F023';
        font: 14px fontawesome;
          color: #5b5b5b;
      }
      
      .form input[type="password"], .form input[type="text"], .form button {
        width: 100%;
      }
      
      .form-login label,
      .form-login input[type="text"],
      .form-login input[type="password"],
      .form-login button {
        border-radius: 0.25rem;
        padding: 1rem;
        color: #3A3F44;  
      }
      
      .form-login label {
        background-color: #222222;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
      
      .form-login input[type="text"], .form-login input[type="password"] {
        background-color: #ffffff;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      .form-login input[type="text"]:focus, .form-login input[type="text"]:hover, .form-login input[type="password"]:focus, .form-login input[type="password"]:hover {
        background-color: #eeeeee;
      }
      .form-login button {
        background-color: #00B9BC;
        color: #eee;
        font-weight: bold;
        text-transform: uppercase;
      }
      .form-login button:focus, .form-login button:hover {
        background-color: #197071;
      }
      .form-field {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 2rem;
      }
      .form-field .hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    .login-wrapper,
    .loader-wrapper {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.6);
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        top: 0;
        left: 0;
        z-index: 1000;
        color: #fff;
        text-align: center;
    }

        .wineglass {
            height: 36px;
            position: relative;
            margin-bottom: 15px;
            display: none;
        }

        .wineglass:after {
            content: '';
            position: absolute;
            top: 47px;
            left: 5px;
            width: 20px;
            height: 4px;
            background: white;
            box-shadow: 0 0 1px white;
        }

        .wineglass .top {
            background: white;
            width: 30px;
            height: 36px;
            border-radius: 0 0 36px 36px;
            box-shadow: 0 0 1px white;
        }

            .wineglass .top:before {
                content: '';
                position: absolute;
                left: 3px;
                bottom: 3px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: linear-gradient(transparent 50%, #722F37   50%);
            }

        .wineglass .top:after {
            content: '';
            position: absolute;
            top: 35px;
            left: 13px;
            width: 4px;
            height: 13px;
            background: white;
            box-shadow: 0 0 1px white;
        }

        .wineglass.left {
            margin-right: 11px;
        }

.loader .grapes {
    height: 75px;
    width: 75px;
    position: relative;
    margin-bottom: 15px;
    background: transparent url("/grapes.svg") no-repeat 50% 50%;
    background-size: contain;
    display: inline-block;
}
.loader.init .grapes {
    display: none;
}
    .loader.init .wineglass {
        display: inline-block;
    }
    .loader.init .wineglass.left .top:before {
        -webkit-animation: glass-rotate-right 1.5s linear infinite;
                animation: glass-rotate-right 1.5s linear infinite;
    }
    .loader.init .wineglass.right .top:before {
        -webkit-animation: glass-rotate-left 1.5s linear infinite;
                animation: glass-rotate-left 1.5s linear infinite;
    }
    .loader.init .wineglass.left  {
        -webkit-animation: glass-rotate-left 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
                animation: glass-rotate-left 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
    }
    .loader.init .wineglass.right  {
        -webkit-animation: glass-rotate-right 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
                animation: glass-rotate-right 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
    }
    .loader.init ul {
        -webkit-animation: 1.5s loader-fadeInUp infinite linear .5s;
                animation: 1.5s loader-fadeInUp infinite linear .5s;
    }
    .loader ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 70px;
        height: 25px;
        position: relative;
        opacity: 0;
    }

        .loader ul li {
            width: 3px;
            height: 15px;
            background: white;
            position: absolute;
            box-shadow: 0 0 1px white;
            -webkit-transform-origin: bottom;
                    transform-origin: bottom;
        }

            .loader ul li:nth-child(1) {
                left: 26px;
                bottom: 5px;
                -webkit-transform: rotate(-35deg);
                        transform: rotate(-35deg);
            }

            .loader ul li:nth-child(2) {
                left: 34px;
                bottom: 8px;
            }

            .loader ul li:nth-child(3) {
                left: 42px;
                bottom: 5px;
                -webkit-transform: rotate(35deg);
                        transform: rotate(35deg);
            }

.show-large {
    display: none;
    font-size: .7em
}

@media screen and (min-width: 40em) {
    .main {
        padding: 2em;
        padding: 1em;
        font-size: 3em;
        min-width: 50%;
    }

    .mobile-show {
        display: none;
    }

    table#wines {
        font-size: 40%;
    }

    .nl-form .nl-submit,
    .nl-button {
        font-size: 40%;
    }
    .nl-field ul {
        font-size: 60%;
    }
}

@media screen and (min-width: 1600px) {
    .show-large {
        display: inline-block;
    }
}


.favourite {
    font-size: 1.5em;
    line-height: 1;
    vertical-align: text-bottom;
    margin-right: .2em;
}

    .favourite > span {
        display: inline-block;
        position: relative;
        color: #ccc;
    }

        .favourite > span:before {
            content: "\2605";
            position: absolute;
            color: #ccc;

            -webkit-animation-name: star-off;

                    animation-name: star-off;
            -webkit-animation-delay: 50ms;
                    animation-delay: 50ms;
            -webkit-animation-duration: 600ms;
                    animation-duration: 600ms;
            -webkit-animation-timing-function: ease-out;
                    animation-timing-function: ease-out;
            -webkit-animation-fill-mode: backwards;
                    animation-fill-mode: backwards;
        }

        .favourite > span:hover {
            cursor: pointer;
            cursor: hand;
        }

    .isFavourite .favourite > span:before {
        color: gold;
        -webkit-animation-name: star-on;
                animation-name: star-on;
    }
@-webkit-keyframes star-on {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        color: gold;
        opacity: .8;
    }
    80% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}
@keyframes star-on {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        color: gold;
        opacity: .8;
    }
    80% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes star-off {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        color: #ccc;
        opacity: .8;
    }
    80% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}
@keyframes star-off {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        color: #ccc;
        opacity: .8;
    }
    80% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 40em) {
    .favourite {
        font-size: 1.5em
    }
    .mobile-hide {
        display: none;
    }
    td {
        position: relative;
    }
    .in-stock {
        content: attr(title);
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: 75%;
        font-style: italic;
        padding-right: .3em;
        background: #aaa;
        border-radius: 1em;
        color: #ffffff;
        text-align: center;
        width: 1.5em;
    }
}

@-webkit-keyframes glass-rotate-left {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(22deg);
                transform: rotate(22deg);
    }
}

@keyframes glass-rotate-left {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(22deg);
                transform: rotate(22deg);
    }
}

@-webkit-keyframes glass-rotate-right {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(-22deg);
                transform: rotate(-22deg);
    }
}

@keyframes glass-rotate-right {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(-22deg);
                transform: rotate(-22deg);
    }
}

@-webkit-keyframes loader-fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }

    33% {
        opacity: 1;
        -webkit-transform: scaleY(1.4);
                transform: scaleY(1.4);
    }

    64% {
        opacity: .1;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scaleY(.3);
                transform: scaleY(.3);
    }
}

@keyframes loader-fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }

    33% {
        opacity: 1;
        -webkit-transform: scaleY(1.4);
                transform: scaleY(1.4);
    }

    64% {
        opacity: .1;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scaleY(.3);
                transform: scaleY(.3);
    }
}
