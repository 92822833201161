@font-face {
	font-family: 'nlicons';
	src:url('/fonts/nlicons.eot');
	src:url('/fonts/nlicons.eot?#iefix') format('embedded-opentype'),
		url('/fonts/nlicons.woff') format('font-woff'),
		url('/fonts/nlicons.ttf') format('truetype'),
		url('/fonts/nlicons.svg#nlicons') format('svg');
	font-weight: normal;
	font-style: normal;
}
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css');

.first-slide {
    color: white;
    background: #046A38; /* ##c54245;*/
    font-weight: 300;
}
#terms{
    max-width: 1000px;
    margin: 0 auto;
    font-size: small;
}
.main {
    margin: 0 auto;
    padding: .5em;
    max-width: 60em;
    font-size: 1.6em;
    position: relative;
    z-index: 50;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
@media screen and (min-width: 30em) {
    .main {
        font-size: 2em;
    }
}
    table {
        font-size: 60%;
        margin-top: 1em;
        width: 100%;
    }

        table th, table td {
            text-align: left;
            padding: 8px;
        }

        table tbody tr:nth-child(odd) {
            background: #f5f5f5;
        }
        table tbody tr.secret {
            background: #e7fff3;
        }

        table td p {
            margin: 0;
        }

            table td p + p {
                margin-top: .5em;
            }

    /* general style for the form */
    .nl-form {
        text-align: center;
        
        text-shadow: 1px 1px 4px black;
    }

        .nl-form ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-shadow: none;
        }

        /* normalize the input elements, make them look like everything else */
        .nl-form input,
        .nl-form select,
        .nl-form button,
        .nl-button {
            text-shadow: none;
            border: none;
            background: transparent;
            font-family: inherit;
            font-size: inherit;
            color: inherit;
            font-weight: inherit;
            line-height: inherit;
            display: inline-block;
            padding: 0;
            margin: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

            .nl-form input:focus {
                outline: none;
            }

        .nl-form input {
            max-width: 3em;
            text-align: right;
        }

        .nl-form select {
            max-width: 6em;
        }

    /* custom field (drop-down, text element) styling  */
    .nl-field {
        display: inline-block;
        position: relative;
        margin: 0 .5rem;
    }

        .nl-field.nl-field-open {
            z-index: 10000;
        }

    /* the toggle is the visible part in the form */
    .nl-field-toggle,
    .nl-form input,
    .nl-form select {
        line-height: inherit;
        display: inline-block;
        color: white;
        cursor: pointer;
        border-bottom: 1px dashed white;
    }

    /* drop-down list / text element */
.nl-field ul {
    position: fixed;
    visibility: hidden;
    background: #76C3BD;
    background: rgba(118, 195, 189, .9);
    left: 0;
    font-size: 80%;
    opacity: 0;
    transform: translateY(-30%) scale(0.9);
    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
}

    .nl-field.nl-field-open ul {
        visibility: visible;
        opacity: 1;
        transform: translateY(-40%) scale(1);
        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
    }

    .nl-field ul li {
        color: #fff;
        position: relative;
    }

    .nl-dd ul li {
        padding: 0 1em;
        cursor: pointer;
        white-space: nowrap;
    }


.nl-dd ul li.nl-dd-checked {
    color: #478982;
}

     .nl-dd ul li:hover {
        background: rgba(0,0,0,0.05);
    }

         .nl-dd ul li:hover:active {
            color: #478982;
        }

    /* icons for some elements */
    .nl-dd ul li.nl-dd-checked:before,
    .nl-submit:before,
    .nl-button:before,
    .nl-field-go:before {
        font-family: 'nlicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }

    .nl-dd ul li.nl-dd-checked:before {
        content: "\e000";
        position: absolute;
        right: 1em;
        font-size: 50%;
        line-height: 3;
    }

    .nl-ti-text ul {
        min-width: 5em;
    }
        .nl-ti-text ul li.nl-ti-input {
            text-align:left;
        }

        .nl-ti-text ul li.nl-ti-input input {
            width: 100%;
            padding: 0.2em 0 0.2em 0.5em;
            border-bottom: none;
            color: #fff;
            max-width: 3em;
            text-align: left;
        }

    .nl-form .nl-field-go {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        cursor: pointer;
        background: rgba(0,0,0,0.1);
        width: 1.8em;
        text-align: center;
        color: transparent;
    }

    .nl-field-go:before {
        content: "\e001";
        font-size: 75%;
        color: #fff;
        width: 100%;
        line-height: 2.5;
        display: block;
    }

    /* custom placeholder color */
    .nl-form input::-webkit-input-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-webkit-input-placeholder,
    .nl-form input:focus::-webkit-input-placeholder {
        color: rgba(255,255,255,0.2);
    }

    .nl-form input::-moz-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-moz-placeholder,
    .nl-form input:focus::-moz-placeholder {
        color: rgba(255,255,255,0.2);
    }

    .nl-form input:-ms-input-placeholder {
        color: rgba(255,255,255,0.8);
    }

    .nl-form input:active::-ms-input-placeholder,
    .nl-form input:focus::-ms-input-placeholder {
        color: rgba(255,255,255,0.2);
    }

    /* example field below text input */
    .nl-ti-text ul li.nl-ti-example {
        font-size: 40%;
        font-style: italic;
        font-weight: 400;
        padding: 0.4em 1em;
        color: rgba(0,0,0,0.2);
        border-top: 1px dashed rgba(255,255,255,0.7);
    }

        .nl-ti-text ul li.nl-ti-example em {
            color: #fff
        }


    .nl-form .nl-submit,
    .nl-button {
        line-height: 3;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        background: #bf9b30; /*#76C3BD; */
        color: #fff;
        padding: 0 1em 0 0;
        font-size: 50%;
        font-weight: bold;
        letter-spacing: 3px;
        margin-top: 1em;
    }
    .nl-button a{ color: #fff; text-decoration: none }

        .nl-submit:before,
        .nl-button:before {
            content: "\e001";
            color: #fff;
            float: left;
            padding: 0 0.7em;
            margin: 0 0.8em 0 0;
            background:#a67c00; /* #69B1A9;*/
        }

        .nl-button:before {
            content: "\e001";
        }
         .nl-submit + .nl-submit {margin-left:1em}

    /* overlay becomes visible when a field is opened */
    .nl-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        opacity: 0;
        z-index: 9999;
        visibility: hidden;
        -webkit-transition: visibility 0s 0.3s, opacity 0.3s;
        -moz-transition: visibility 0s 0.3s, opacity 0.3s;
        transition: visibility 0s 0.3s, opacity 0.3s;
    }
    .nl-form small.divider{
        display: block;
        margin-top: .4em;
    }

    .nl-field.nl-field-open ~ .nl-overlay {
        opacity: 1;
        visibility: visible;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s;
    }
    .login-wrapper .login {
        background:rgba(58,63,68,0.5);
        border-radius: 5px;
        box-shadow: 0 1.5px 0 0 rgba(0,0,0,0.1);
        width:450px;
        display: flex;  
        flex-direction: column;
    }
    .form{
        padding:25px 25px 0;
        margin: 20px 20px 0;	
        border-radius: 3px;
    }
    .form input {
        border: 0;
        color: inherit;
        font: inherit;
        margin: 0;
        outline: 0;
        padding: 0;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;
      }
      
      .form-login .user:before {
        content: '\f007';
        font: 14px fontawesome;
          color: #5b5b5b;
      }
      
      .form-login .lock:before {
        content: '\f023';
        font: 14px fontawesome;
          color: #5b5b5b;
      }
      
      .form input[type="password"], .form input[type="text"], .form button {
        width: 100%;
      }
      
      .form-login label,
      .form-login input[type="text"],
      .form-login input[type="password"],
      .form-login button {
        border-radius: 0.25rem;
        padding: 1rem;
        color: #3A3F44;  
      }
      
      .form-login label {
        background-color: #222222;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
      
      .form-login input[type="text"], .form-login input[type="password"] {
        background-color: #ffffff;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      .form-login input[type="text"]:focus, .form-login input[type="text"]:hover, .form-login input[type="password"]:focus, .form-login input[type="password"]:hover {
        background-color: #eeeeee;
      }
      .form-login button {
        background-color: #00B9BC;
        color: #eee;
        font-weight: bold;
        text-transform: uppercase;
      }
      .form-login button:focus, .form-login button:hover {
        background-color: #197071;
      }
      .form-field {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 2rem;
      }
      .form-field .hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    .login-wrapper,
    .loader-wrapper {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 1000;
        color: #fff;
        text-align: center;
    }

        .wineglass {
            height: 36px;
            position: relative;
            margin-bottom: 15px;
            display: none;
        }

        .wineglass:after {
            content: '';
            position: absolute;
            top: 47px;
            left: 5px;
            width: 20px;
            height: 4px;
            background: white;
            box-shadow: 0 0 1px white;
        }

        .wineglass .top {
            background: white;
            width: 30px;
            height: 36px;
            border-radius: 0 0 36px 36px;
            box-shadow: 0 0 1px white;
        }

            .wineglass .top:before {
                content: '';
                position: absolute;
                left: 3px;
                bottom: 3px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: linear-gradient(transparent 50%, #722F37   50%);
            }

        .wineglass .top:after {
            content: '';
            position: absolute;
            top: 35px;
            left: 13px;
            width: 4px;
            height: 13px;
            background: white;
            box-shadow: 0 0 1px white;
        }

        .wineglass.left {
            margin-right: 11px;
        }

.loader .grapes {
    height: 75px;
    width: 75px;
    position: relative;
    margin-bottom: 15px;
    background: transparent url("/grapes.svg") no-repeat 50% 50%;
    background-size: contain;
    display: inline-block;
}
.loader.init .grapes {
    display: none;
}
    .loader.init .wineglass {
        display: inline-block;
    }
    .loader.init .wineglass.left .top:before {
        animation: glass-rotate-right 1.5s linear infinite;
    }
    .loader.init .wineglass.right .top:before {
        animation: glass-rotate-left 1.5s linear infinite;
    }
    .loader.init .wineglass.left  {
        animation: glass-rotate-left 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
    }
    .loader.init .wineglass.right  {
        animation: glass-rotate-right 1.5s cubic-bezier(.39,1.52,.46,.92) infinite .5s;
    }
    .loader.init ul {
        animation: 1.5s loader-fadeInUp infinite linear .5s;
    }
    .loader ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 70px;
        height: 25px;
        position: relative;
        opacity: 0;
    }

        .loader ul li {
            width: 3px;
            height: 15px;
            background: white;
            position: absolute;
            box-shadow: 0 0 1px white;
            transform-origin: bottom;
        }

            .loader ul li:nth-child(1) {
                left: 26px;
                bottom: 5px;
                transform: rotate(-35deg);
            }

            .loader ul li:nth-child(2) {
                left: 34px;
                bottom: 8px;
            }

            .loader ul li:nth-child(3) {
                left: 42px;
                bottom: 5px;
                transform: rotate(35deg);
            }

.show-large {
    display: none;
    font-size: .7em
}

@media screen and (min-width: 40em) {
    .main {
        padding: 2em;
        padding: 1em;
        font-size: 3em;
        min-width: 50%;
    }

    .mobile-show {
        display: none;
    }

    table#wines {
        font-size: 40%;
    }

    .nl-form .nl-submit,
    .nl-button {
        font-size: 40%;
    }
    .nl-field ul {
        font-size: 60%;
    }
}

@media screen and (min-width: 1600px) {
    .show-large {
        display: inline-block;
    }
}


.favourite {
    font-size: 1.5em;
    line-height: 1;
    vertical-align: text-bottom;
    margin-right: .2em;
}

    .favourite > span {
        display: inline-block;
        position: relative;
        color: #ccc;
    }

        .favourite > span:before {
            content: "\2605";
            position: absolute;
            color: #ccc;

            animation-name: star-off;
            animation-delay: 50ms;
            animation-duration: 600ms;
            animation-timing-function: ease-out;
            animation-fill-mode: backwards;
        }

        .favourite > span:hover {
            cursor: pointer;
            cursor: hand;
        }

    .isFavourite .favourite > span:before {
        color: gold;
        animation-name: star-on;
    }
@keyframes star-on {
    0% {
        transform: scale(1);
    }
    50% {
        color: gold;
        opacity: .8;
    }
    80% {
        transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}
@keyframes star-off {
    0% {
        transform: scale(1);
    }
    50% {
        color: #ccc;
        opacity: .8;
    }
    80% {
        transform: scale(5);
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 40em) {
    .favourite {
        font-size: 1.5em
    }
    .mobile-hide {
        display: none;
    }
    td {
        position: relative;
    }
    .in-stock {
        content: attr(title);
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: 75%;
        font-style: italic;
        padding-right: .3em;
        background: #aaa;
        border-radius: 1em;
        color: #ffffff;
        text-align: center;
        width: 1.5em;
    }
}

@keyframes glass-rotate-left {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(22deg);
    }
}

@keyframes glass-rotate-right {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-22deg);
    }
}

@keyframes loader-fadeInUp {
    0% {
        opacity: 0;
        transform: scaleY(1);
    }

    33% {
        opacity: 1;
        transform: scaleY(1.4);
    }

    64% {
        opacity: .1;
        transform: scaleY(1);
    }

    100% {
        opacity: 0;
        transform: scaleY(.3);
    }
}